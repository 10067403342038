<template>
  <div class="member-card-edit">
    <PageTitle
      title="會員卡範本詳情"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({name: 'MemberCardSetting'})"
    />
    <section v-loading="loading" class="side-highlight-container">
      <BaseElForm ref="formRef" :rules="formRules" :model="formData" label-position="top">
        <BaseElFormItem label="於卡面顯示會員等級名稱">
          <BaseElSwitch
            v-model="formData.displayName"
            inactive-text="關閉"
            active-text="開啟"
          />
        </BaseElFormItem>
        <BaseElFormItem label="於卡面顯示會員手機號碼">
          <BaseElSwitch
            v-model="formData.displayPhone"
            inactive-text="關閉"
            active-text="開啟"
          />
        </BaseElFormItem>

        <el-divider />

        <BaseElFormItem label="範本名稱" prop="name">
          <BaseElInput v-model="formData.name" :disabled="isSystemStyle" />
        </BaseElFormItem>

        <el-divider />

        <div class="flex items-center" style="gap: 8px; margin-bottom: 20px">
          <div class="flex items-center" style="gap: 4px">
            <p v-show="!isSystemStyle" class="text-danger">*</p>
            <p class="font-medium text-normal">會員卡面圖示</p>
          </div>
          <p v-show="!isSystemStyle" class="text-sm text-gray-80">( 建議上傳尺寸 1280x800 px，限用 jpg、jpeg、png )</p>
        </div>

        <div v-if="loaded" class="flex flex-col gap-[12px]">
          <div
            v-for="(card) in formData.cardSettings"
            v-show="card.level <= 4"
            :key="`${card.style}-${card.level}`"
          >
            <div class="flex items-center gap-[20px]" :name="`${card.style}-${card.level}`">
              <p>等級 {{ card.level }}</p>

              <div class="w-[120px] h-[72px] overflow-hidden">
                <img :id="`img-LV${card.level}`" :src="card.img">
              </div>

              <div class="flex items-center gap-[12px]">
                <FileInputTextBtn
                  v-if="!isSystemStyle"
                  :key="`${card.style}-${card.level}`"
                  @change="imageChange($event, card.level)"
                >
                  上傳
                </FileInputTextBtn>
                <p class="cursor-pointer" @click="onDownload(card)">下載</p>
              </div>
            </div>
          </div>
        </div>
      </BaseElForm>
    </section>

    <WarningDialog
      v-if="modal.warn"
      title="提示"
      hideCancel
      btnString="同意"
      cancelBtnString="不同意"
      @confirm="onConfirmWarning"
      @close="modal.warn = false"
    >
      <template slot="body">
        <div>
          本公司保有此卡面圖樣完整著作權，切勿外流或進行其他商業用途。
        </div>
      </template>
    </WarningDialog>

    <ImageCropper
      v-if="modal.cropper"
      :image="imgHolder"
      :ratio="[1280,800]"
      @close="onCropperClose"
      @done="getCroppedImage"
    />

    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="$router.push({name: 'MemberCardSetting'})"
      @confirm="onSubmit"
    />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, reactive, set, ref } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import FileInputTextBtn from '@/components/Button/FileInputTextBtn.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import { get, map } from 'lodash'
import { useRoute, useRouter } from 'vue-router/composables'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { systemMemberCardStyles } from '@/config/memberCard'
import { downloadURI, imgSrc } from '@/utils/helper'
import { UpdateMemberConfig, GetMemberConfig } from '@/api/memberConfig'
import { UpdateCustomCardTemplate, CreateCustomCardTemplate, FindCustomCardTemplate } from '@/api/memberCard'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'

export default defineComponent({
  name: 'MemberCardEdit',
  components: { PageTitle, PageFixedFooter, ImageCropper, FileInputTextBtn, WarningDialog },
  setup (props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const { shopId } = useShop()
    const { simpleFetch } = useFetch()
    const { initFormData, formData, formRef, loading, formSubmit } = useBaseForm()
    const modal = reactive({
      warn: false,
      cropper: false,
    })

    const configData = ref({})
    const selectLevel = ref(null)
    const selectCard = ref(null)
    const imgHolder = ref(null)
    const loaded = ref(false)

    const targetId = computed(() => get(route.params, 'id'))
    const cardStyleName = computed(() => route.params.styleName || 'classic')
    const isSystemStyle = computed(() => {
      if (!targetId.value) return false
      if (targetId.value.includes('@system-')) return true
      return false
    })

    initFormData({
      displayName: true,
      displayPhone: true,
      name: null,
      cardSettings: [],
    })

    // 表單欄位規則
    const formRules = computed(() => {
      if (isSystemStyle.value) return {}
      const rules = {
        name: [noEmptyRules()],
        levelImages: [noEmptyRules()],
      }

      return rules
    })

    const test = (level) => {
      console.log(level)
    }

    const onCropperClose = () => {
      modal.cropper = false
      imgHolder.value = null
    }

    // 需要時再使用：
    // onFormRulesChangeClearValidate(formRef, formRules)

    const getCroppedImage = (e) => {
      const { res, imgDataUrl } = e
      const target = formData.cardSettings[selectLevel.value - 1]
      target.customImageId = res.id
      target.style = 'custom'
      target.img = imgSrc(300, res)
      target.dataUrl = imgDataUrl
      modal.cropper = false
      selectLevel.value = null
    }

    const imageChange = (img, level) => {
      selectLevel.value = level
      imgHolder.value = img
      modal.cropper = true
    }

    const defaultCardImages = () => {
      if (isSystemStyle.value || !route.params.styleName) {
        const images = []
        for (let count = 0; count < 6; count++) {
          const level = count + 1
          let img = require(`@/assets/memberCard/classic/LV${level}.webp`)
          if (cardStyleName.value) img = require(`@/assets/memberCard/${cardStyleName.value}/LV${level}.webp`)
          images.push({
            style: cardStyleName.value,
            level,
            customImageId: null,
            img,
          })
        }

        set(formData, 'cardSettings', images)
      } else {
        formData.cardSettings.forEach(card => {
          if (card.style !== 'custom') card.img = require(`@/assets/memberCard/${card.style}/LV${card.level}.webp`)
          if (card.style === 'custom') card.img = imgSrc(300, card.CustomImage)
        })
      }
    }

    const submitData = computed(() => {
      return {
        displayCardLevelName: formData.displayName,
        displayCardPhoneNumber: formData.displayPhone,
        name: formData.name,
        cardSettings: map(formData.cardSettings, (card) => {
          return {
            level: card.level,
            style: card.style,
            customImageId: card.customImageId || undefined,
          }
        }),
      }
    })

    // 同步表單資料
    const syncFormData = (data) => {
      formData.name = data.name
      formData.cardSettings = data.cardSettings
    }

    // 送出表單
    const onSubmit = async () => {
      const handler = async (data, { emit, loading }) => {
        let apiMethod
        if (!targetId.value) apiMethod = CreateCustomCardTemplate
        if (targetId.value) apiMethod = UpdateCustomCardTemplate

        try {
          await simpleFetch(UpdateMemberConfig, {
            shopId: shopId.value,
            displayCardLevelName: get(data, 'displayCardLevelName'),
            displayCardPhoneNumber: get(data, 'displayCardPhoneNumber'),
          })
          if (!isSystemStyle.value) {
            await simpleFetch(apiMethod, {
              shopId: shopId.value,
              id: targetId.value,
              name: get(data, 'name'),
              cardSettings: get(data, 'cardSettings'),
            })
          }

          // await Promise.all([
          //   simpleFetch(UpdateMemberConfig, {
          //     shopId: shopId.value,
          //     displayCardLevelName: get(data, 'displayCardLevelName'),
          //     displayCardPhoneNumber: get(data, 'displayCardPhoneNumber'),
          //   }),
          //   simpleFetch(apiMethod, {
          //     shopId: shopId.value,
          //     id: targetId.value,
          //     name: get(data, 'name'),
          //     cardSettings: get(data, 'cardSettings'),
          //   }),
          // ])

          loading.value = false
          router.push({ name: 'MemberCardSetting' })
        } catch (error) {
          console.log(error)
        }
      }
      await formSubmit(handler, submitData.value, { emit, loading })
    }

    const onDownload = (card) => {
      if (card.style !== 'custom') {
        selectCard.value = card
        modal.warn = true
        return
      }
      let newPage = false
      if (card.style === 'custom') newPage = true
      downloadURI(card.img, `LV${card.level}`, newPage)
    }

    const onConfirmWarning = () => {
      const card = selectCard.value
      downloadURI(card.img, `LV${card.level}`, true)
      selectCard.value = null
      modal.warn = false
    }

    onMounted(async () => {
      await simpleFetch(GetMemberConfig, { shopId: shopId.value }, (res) => {
        configData.value = res
        formData.displayName = get(res, 'displayCardLevelName')
        formData.displayPhone = get(res, 'displayCardPhoneNumber')
      })
      if (!isSystemStyle.value && route.params.styleName) {
        await simpleFetch(FindCustomCardTemplate, { shopId: shopId.value, id: targetId.value },
          (res) => {
            syncFormData(res)
          },
        )
      } else if (isSystemStyle.value) {
        formData.name = cardStyleName.value
      }

      defaultCardImages()
      loaded.value = true
    })

    return {
      loading,
      formRef,
      formData,
      formRules,
      onSubmit,
      modal,
      imageChange,
      isSystemStyle,
      imgHolder,
      getCroppedImage,
      imgSrc,
      onDownload,
      submitData,
      selectLevel,
      loaded,
      test,
      onConfirmWarning,
      onCropperClose,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
