<template>
  <div class="file-input-text-btn">
    <div class="upload-wrapper">
      <label title="點擊上傳圖片" class="custom-file-upload">
        <slot>
          Upload
        </slot>
        <input
          ref="inputRef"
          type="file"
          accept=".jpg, .jpeg, .png"
          @change="imgInput"
          @click="onFileInputClick"
        >
      </label>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { imgLoad } from '@/utils/helper'

export default defineComponent({
  name: 'FileInputTextBtn',
  props: {
    label: String,
  },
  emits: ['change'],
  setup (props, { emit }) {
    const inputRef = ref(null)

    const imgInput = (e) => {
      if (!e) return
      emit('change', imgLoad(e))
      inputRef.value.fileInput.type = 'text'
      inputRef.value.fileInput.type = 'file'
    }

    const onFileInputClick = (event) => {
      console.log('event', event)
      const element = event.target
      element.value = ''
    }

    return { inputRef, imgInput, onFileInputClick }
  },
})
</script>

<style lang="postcss" scoped>
.upload-wrapper {
  position: relative;
}

input[type="file"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    border: 0;
}
.custom-file-upload {
    @apply font-normal cursor-pointer;
}
</style>
